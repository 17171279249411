<template>
  <div style="width: 100%;" @click="handleClear">
    <page-header
      class="absolute"
      style="padding: 20px 30px; width: calc(100% - 335px)"
      :title="__('API Keys')"
    />
    <el-button
      :disabled="!this.canWrite()"
      class="createBtn"
      @click.stop="addAPIKeyItem"
      >{{ __("Add API Key") }}</el-button
    >
    <div style="padding: 0 36px; margin-top: 136px;" v-loading="listLoading">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :content-api="contentAPI"
          :show-slot="true"
          @success="$emit('replaceContent', $event)"
        >
        </PaginationToolbar>
      </el-form>
      <el-scrollbar :native="true">
        <div class="contentListContainer" v-loading="isLoading">
          <el-table
            ref="apiKeyTable"
            class="list-table"
            :data="apikeys"
            highlight-current-row
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
            :row-class-name="inactiveRowClassName"
            v-show="canRead()"
          >
            <el-table-column :label="__('Name')" prop="api_key_name">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.api_key_name }}
                </span>
              </template>
            </el-table-column>

            <el-table-column :label="__('API Key')" prop="api_key">
              <template slot-scope="scope">
                <el-row
                  type="flex"
                  justify="flex-start"
                  style="align-items: center"
                >
                  <span style="margin-left: 20px;">{{
                    scope.row.api_key
                  }}</span>
                </el-row>
              </template>
            </el-table-column>

            <el-table-column :label="__('Account')" prop="ac_name">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.ac_name }}
                </span>
              </template>
            </el-table-column>

            <el-table-column :label="__('Status')" prop="is_enabled">
              <template slot-scope="scope">
                <span>
                  {{ apiKeyActiveStatusText(scope.row.is_enabled) }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import PageHeader from "@/components/PageHeader";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import { EventBus } from "@/EventBus";

export default {
  name: "ApiKeysManageList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    PageHeader
  },
  data() {
    return {
      search: "",
      filters: ["api_key_name"],
      sortByColumns: ["api_key_name", "user_api_key_id"],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10
      }
    };
  },

  computed: {
    ...mapState("apikeys", {
      apikeys: state => state.api_keys,
      isLoading: state => state.isLoading
    })
  },

  mounted() {},
  methods: {
    ...mapActions("apikeys", {
      contentAPI: "getUserApiKeys",
      safeDeleteContent: "deleteUserApiKey",
      undoDeleteContent: "undoDeleteUserApiKey"
    }),
    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },

    /**
     * Get the status text from the isEnabled status of an API key
     */
    apiKeyActiveStatusText(isEnabled) {
      return isEnabled === 1 ? __("Active") : __("Inactive");
    },

    addAPIKeyItem() {
      // if (
      //   this.selectedServiceProviderId !== "all" &&
      //   this.selectedAccountId != "all"
      // ) {
      this.createContentItem();
      // } else {
      //   this.$message({
      //     message: `Please select an account under the service provider to add an API Key`,
      //     type: "info"
      //   });
      // }
    },
    handleDoubleClick(row, column, event) {
      event.stopPropagation();
      this.handleEdit(null, row);
    },

    handleClear() {
      this.$refs.apiKeyTable && this.$refs.apiKeyTable.setCurrentRow();
      this.handleClearSelection();
    },

    handleDeleteItem(index, account) {
      this.handleDelete(account);
    },
    inactiveRowClassName({ row }) {
      return row.is_enabled === 1 ? "" : "inactive__row";
    }
  },
  watch: {
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== -1 && val !== null && val !== undefined) {
          this.$refs.apiKeyTable &&
            this.$refs.apiKeyTable.setCurrentRow(this.apikeys[val]);
        } else if (val === -1) {
          this.$refs.apiKeyTable && this.$refs.apiKeyTable.setCurrentRow();
        }
      }
    },
    selectedServiceProviderId: {
      immediate: true,
      handler: function(val) {
        if (val !== -1 && val !== null) {
          EventBus.$emit("list-changed", null);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-list.scss";
@import "~@/styles/pagination-toolbar.scss";
.rounded-menu-icon {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  object-fit: cover;
  background: white;
}
</style>
